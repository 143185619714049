import { ProfileOverrides } from 'models/ProfileOverrides';
import shopOverridesRaw from './shop-overrides.json';
import { CompanyProfile } from 'models/Profile';

const shopOverrides = shopOverridesRaw as unknown as ProfileOverrides;

// See https://wiki.trustedshops.com/x/IDarAg
export function applyShopOverrides(profile: CompanyProfile | any) {
  if (!profile) {
    return null;
  }

  if (profile.tsId) {
    const profileWithOverrides = shopOverrides[profile.tsId];

    if (profileWithOverrides) {
      if (profileWithOverrides.contactData) {
        profileWithOverrides.contactData = Object.assign(
          profile.contactData,
          profileWithOverrides.contactData
        );
      }

      if (profileWithOverrides.memberDetails) {
        profileWithOverrides.memberDetails = Object.assign(
          profile.memberDetails,
          profileWithOverrides.memberDetails
        );
      }

      Object.assign(profile, profileWithOverrides);
    }
  }

  return profile;
}

import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  StatusText,
  ReviewProviderStyled,
  VerifiedIcon,
  VerifiedReviewTooltipChildren,
  VerifiedTooltipWrapper
} from 'components/Review/ReviewProvider/ReviewProvider.styles';
import {
  colorsVariables,
  Tooltip,
  TooltipHandleFunctions,
  TooltipPlacement
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import { Review, ReviewVerificationStatusEnum } from 'reviews-api';
import { InModeration } from './InModeration';
import { ImportedProvider } from './ImportedProvider';
import { VERIFICATION_TOOLTIP } from 'services/data-test-attributes';
import VerifiedReviewTooltipContent from './VerifiedReviewTooltipContent/VerifiedReviewTooltipContent';

export const ReviewProvider = ({
  review,
  tooltipPlacement,
  isTooltipOnMobile
}: {
  review: Review;
  tooltipPlacement?: TooltipPlacement;
  isTooltipOnMobile?: boolean;
}) => {
  const isVerifiedReview: boolean =
    review.verificationStatus === ReviewVerificationStatusEnum.MemberVerified;
  return (
    <ReviewProviderStyled isVerifiedProvider={isVerifiedReview}>
      {review.inModeration ? (
        <InModeration />
      ) : (
        <Provider
          review={review}
          isVerifiedReview={isVerifiedReview}
          tooltipPlacement={tooltipPlacement}
          isTooltipOnMobile={isTooltipOnMobile}
        />
      )}
    </ReviewProviderStyled>
  );
};

export const Provider = ({
  review,
  tooltipPlacement,
  isVerifiedReview,
  isTooltipOnMobile
}: {
  review: Review;
  tooltipPlacement?: TooltipPlacement;
  isVerifiedReview: boolean;
  isTooltipOnMobile?: boolean;
}) => {
  return (
    <>
      {isVerifiedReview ? (
        <VerifiedProvider
          uniqueId={review.id}
          tooltipPlacement={tooltipPlacement}
          isTooltipOnMobile={isTooltipOnMobile}
        />
      ) : (
        <ImportedProvider review={review} />
      )}
    </>
  );
};

export const VerifiedProvider = ({
  uniqueId,
  tooltipPlacement = 'left',
  isTooltipOnMobile
}: {
  uniqueId: string;
  tooltipPlacement?: TooltipPlacement;
  isTooltipOnMobile?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const refForTooltip = useRef<HTMLDivElement>(null);
  const tooltipComponentRef = useRef<TooltipHandleFunctions>(null);

  return (
    <Tooltip
      childrenRef={refForTooltip}
      ref={tooltipComponentRef}
      uniqueId={'verified-review-' + uniqueId}
      content={
        <VerifiedReviewTooltipContent isTooltipOnMobile={isTooltipOnMobile} />
      }
      placement={tooltipPlacement}
      mobileElementType={isTooltipOnMobile ? 'tooltip' : 'drawer'}
      drawerHeadline={formatMessage({
        id: 'COMPANYPROFILES.RATINGS.SINGLE.VERIFIED_REVIEW'
      })}
    >
      <VerifiedReviewTooltipChildren ref={refForTooltip}>
        <VerifiedTooltipWrapper>
          <VerifiedIcon
            data-test={VERIFICATION_TOOLTIP}
            iconName="icon-feedback-verified"
            iconSize="14px"
            color={colorsVariables.BLUE_700}
          />
          <span>&nbsp;</span>
          <StatusText isVerifiedProvider>
            {formatMessage({
              id: 'COMPANYPROFILES.RATINGS.SINGLE.VERIFIED_REVIEW'
            })}
          </StatusText>
        </VerifiedTooltipWrapper>
      </VerifiedReviewTooltipChildren>
    </Tooltip>
  );
};

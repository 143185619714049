import React from 'react';
import {
  Stars,
  TooltipPlacement
} from '@trustedshops/tps-seo-shared-frontend-react-components';
import AuthorInfo from 'components/Review/AuthorInfo/AuthorInfo';
import {
  HeaderMainContent,
  RatingAndDateWrapper,
  ReviewCardHeader,
  ReviewHeader as ReviewHeaderStyled
} from 'components/Review/ReviewHeader/ReviewHeader.styles';
import { ReviewProvider } from 'components/Review/ReviewProvider/ReviewProvider';
import { Review } from 'reviews-api';
import { ReviewDate } from 'components/Review/ReviewHeader/ReviewDate/ReviewDate';

export function roundedStarsValue(review?: Review): number {
  if (!review || !review.rating) {
    return 0;
  }
  return Math.round(review.rating);
}

const ReviewHeader = ({
  review,
  tooltipId,
  dateTooltipPlacement = 'top',
  providerTooltipPlacement = 'left',
  fullWidthRating,
  isTopFlop,
  isTooltipOnMobile
}: {
  review: Review;
  tooltipId?: string;
  dateTooltipPlacement?: TooltipPlacement;
  providerTooltipPlacement?: TooltipPlacement;
  authorTooltipPlacement?: TooltipPlacement;
  fullWidthRating?: boolean;
  isTopFlop?: boolean;
  isTooltipOnMobile?: boolean;
}) => {
  const { firstName, lastName } = review.customer || {};

  return (
    <ReviewHeaderStyled>
      {firstName && lastName && !isTopFlop && (
        <ReviewCardHeader>
          <AuthorInfo
            review={review}
            isClickable={!!review.customer?.id}
            isTopFlop={isTopFlop}
          />
        </ReviewCardHeader>
      )}
      <HeaderMainContent>
        <RatingAndDateWrapper fullWidthRating={fullWidthRating}>
          <Stars filledValue={roundedStarsValue(review)} iconSize={'24px'} />
          <ReviewDate
            review={review}
            tooltipId={tooltipId}
            tooltipPlacement={dateTooltipPlacement}
            isTooltipOnMobile={isTooltipOnMobile}
          />
        </RatingAndDateWrapper>
        <ReviewProvider
          review={review}
          tooltipPlacement={providerTooltipPlacement}
          isTooltipOnMobile={isTooltipOnMobile}
        />
      </HeaderMainContent>
    </ReviewHeaderStyled>
  );
};

export default ReviewHeader;

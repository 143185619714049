import React, { RefObject, useEffect, useState } from 'react';
import { SetMobileMenuOpen } from 'models/SetMobileMenuOpen';
import { ScrollDirection } from 'models/ScrollDirection';
import {
  ANIMATED_COMPANY_HEADER_ID,
  COMPANY_HEADER_ID
} from 'services/page-section-ids';
import { breakpointsVariables } from 'styles/breakpoints.variables';
import CompanyHeaderContent from 'components/CompanyHeader/CompanyHeaderContent/CompanyHeaderContent';

let animationIterationCount = 0;
export const CompanyHeaderComponent = ({
  translations,
  mobileMenuOpen,
  setMobileMenuOpen,
  pageHeaderRef
}: {
  translations: any;
  mobileMenuOpen: boolean;
  setMobileMenuOpen: SetMobileMenuOpen;
  pageHeaderRef: RefObject<HTMLDivElement>;
}) => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.up);
  const [scrollingAnimationEnabled, setScrollingAnimationEnabled] =
    useState(false);
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const [hasExecutedInitialAnimation, setHasExecutedInitialAnimation] =
    useState(false);

  const classNames: string[] = [scrollDirection];
  /* istanbul ignore if */
  if (scrollingAnimationEnabled) {
    classNames.push('scrolling-animation-enabled');
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      let lastScrollPosition = window.scrollY;

      const updateScrollBasedStates = (currentPosition: number) => {
        const scrollAnimationBreakpointUp =
          window.innerWidth < breakpointsVariables.xs.maxScreeSize ? 360 : 300;
        const isScrollingAnimationEnabled =
          currentScrollPosition > 0 && scrollDirection === ScrollDirection.up
            ? currentPosition > scrollAnimationBreakpointUp
            : currentPosition >=
              (window.innerWidth < breakpointsVariables.xs.maxScreeSize
                ? 500
                : 390);

        setScrollingAnimationEnabled(isScrollingAnimationEnabled);
        setCurrentScrollPosition(window.scrollY);

        const currentDirection =
          currentPosition >= lastScrollPosition
            ? ScrollDirection.down
            : ScrollDirection.up;
        if (currentDirection !== scrollDirection) {
          setScrollDirection(currentDirection);
        }
        lastScrollPosition = currentPosition;
      };

      // Reset animation count when header goes back to static position
      if (!scrollingAnimationEnabled) {
        animationIterationCount = 0;
        setHasExecutedInitialAnimation(false);
      }

      window.addEventListener('scroll', () =>
        updateScrollBasedStates(window.scrollY)
      );

      if (pageHeaderRef && pageHeaderRef.current) {
        pageHeaderRef.current.onanimationstart = () => {
          animationIterationCount++;

          if (animationIterationCount > 2) {
            setHasExecutedInitialAnimation(scrollingAnimationEnabled);
          }
        };
      }
      return () =>
        window.removeEventListener('scroll', () =>
          updateScrollBasedStates(window.scrollY)
        );
    }
    return () => {};
  }, [scrollDirection, scrollingAnimationEnabled, pageHeaderRef]);

  return (
    <>
      {scrollingAnimationEnabled && (
        <CompanyHeaderContent
          id={ANIMATED_COMPANY_HEADER_ID}
          pageHeaderRef={pageHeaderRef}
          mobileMenuOpen={mobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
          translations={translations}
          scrollingAnimationEnabled={scrollingAnimationEnabled}
          classNames={classNames}
          hasExecutedInitialAnimation={hasExecutedInitialAnimation}
        />
      )}
      <CompanyHeaderContent
        id={COMPANY_HEADER_ID}
        mobileMenuOpen={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        translations={translations}
      />
    </>
  );
};

// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * profile-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: vl-tps-seo@trustedshops.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains the contact details of the profile
 * @export
 * @interface ContactData
 */
export interface ContactData {
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactData
     */
    contactForm?: string;
}

export function ContactDataFromJSON(json: any): ContactData {
    return ContactDataFromJSONTyped(json, false);
}

export function ContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContactData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'contactForm': !exists(json, 'contactForm') ? undefined : json['contactForm'],
    };
}

export function ContactDataToJSON(value?: ContactData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'phone': value.phone,
        'email': value.email,
        'contactForm': value.contactForm,
    };
}


import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import {
  CompanyContactInfo,
  CompanyContactDetailBlock,
  CompanyContactDetail,
  CompanyContactIcon,
  CompanyContactDetailLink,
  CompanyDetailsSectionHeadline,
  LegalHeadline,
  ContactInfoSection,
  LegalInfoText,
  CompanyOrgName,
  LegalContactsSection,
  LegalContact,
  MultiProfileWrapper,
  MultiCompanyLinkAsButton
} from 'components/CompanyProfile/ContactInfo/ContactInfo.styles';
import { useStore } from 'context/store';
import { getMultiStoreUrl } from 'helpers/urls';
import { CopyToClipboard } from 'components/CopyToClipboard/CopyToClipboard';
import { snipMailTo } from 'components/CompanyProfile/ContactInfo/snipMailTo';
import { CONTACT_AREA, EMAIL_TOOLTIP } from 'services/data-test-attributes';
import { ProfileWithOverrides } from 'models/ProfileOverrides';
import { Categories } from 'components/CompanyProfile/Categories/Categories';
import { SectionSeparator } from 'components/CompanyProfile/CompanyDetailsAndContactInfo.styles';
import { reviewsStateIs } from 'helpers/reviewStateIs';
import { ReviewsVisibilityState } from 'models/ReviewsVisibilityState';
import { Context } from '@trustedshops/tps-seo-shared-frontend-react-components';
import {
  ExternalLinkIcon,
  GoToCompanyCTAText
} from 'components/CompanyProfile/CompanyDetails.styles';

const ContactInfo = ({
  tooltipId = 'company-contact-info',
  isEmptyProfile
}: {
  tooltipId?: string;
  isEmptyProfile?: boolean;
}) => {
  const { formatMessage } = useIntl();
  const { profile, isMemberProfile, isOpenProfile } = useStore();
  const emailElementRef = useRef<any>();
  const multiStoreUrl = profile.organization?.hasMoreProfiles
    ? getMultiStoreUrl(profile?.organization.encodedName)
    : '';
  const contactLink = profile.contactData?.email?.startsWith('http')
    ? profile.contactData?.email
    : null;
  const hasContactInfo =
    profile.address?.name ||
    profile.address?.street ||
    profile.address?.zipCode ||
    profile.address?.city ||
    profile.address?.countryCode;
  const { __contactExtensions } = profile as ProfileWithOverrides;
  const profileHasReviews = reviewsStateIs(
    profile,
    ReviewsVisibilityState.ReviewsListIsVisible
  );

  const allCategoriesAndKeywords = [
    ...(profile.categories || []),
    ...(profile.keywords || [])
  ];

  const isSummaryVisible = reviewsStateIs(
    profile,
    ReviewsVisibilityState.SummaryIsVisible
  );

  return (
    <CompanyContactInfo data-test={CONTACT_AREA}>
      {(hasContactInfo ||
        profile.contactData?.phone ||
        profile.contactData?.email) && (
        <>
          <SectionSeparator />
          <CompanyDetailsSectionHeadline as={'h4'}>
            {formatMessage({
              id: 'COMPANYPROFILES.CONTACT_INFO.CONTACT_HEADLINE'
            })}
          </CompanyDetailsSectionHeadline>
        </>
      )}
      <ContactInfoSection
        alignment={
          profile.contactData?.phone &&
          profile.contactData?.email &&
          hasContactInfo
            ? 'between'
            : 'left'
        }
        hasNoReviews={!isSummaryVisible}
      >
        {profile.contactData?.phone && (
          <CompanyContactDetailBlock isEmptyProfile={isEmptyProfile}>
            <CompanyContactIcon
              iconName={'icon-label-phone-call'}
              iconSize={'24px'}
            />
            <CompanyContactDetail>
              <CompanyContactDetailLink
                href={'tel:' + profile.contactData.phone}
              >
                {profile.contactData.phone}
              </CompanyContactDetailLink>
            </CompanyContactDetail>
          </CompanyContactDetailBlock>
        )}
        {profile.contactData?.email && (
          <CompanyContactDetailBlock isEmptyProfile={isEmptyProfile}>
            <CompanyContactIcon
              iconName={'icon-feedback-email-alternative'}
              iconSize={'24px'}
            />
            <CompanyContactDetail>
              {contactLink ? (
                <CompanyContactDetailLink
                  data-test={EMAIL_TOOLTIP}
                  href={profile.contactData.email}
                  target="_blank"
                  rel="noreferrer"
                >
                  {profile.contactData.email}
                </CompanyContactDetailLink>
              ) : (
                <CopyToClipboard
                  textToCopy={snipMailTo(profile.contactData.email)}
                  successMessage={
                    'COMPANYPROFILES.CONTACT_INFO.COPY_EMAIL_SUCCESS'
                  }
                  uniqueId={tooltipId + 'sc_email'}
                  refForTooltip={emailElementRef}
                >
                  <CompanyContactDetailLink
                    href="#"
                    ref={emailElementRef}
                    data-test={EMAIL_TOOLTIP}
                  >
                    {snipMailTo(profile.contactData.email)}
                  </CompanyContactDetailLink>
                </CopyToClipboard>
              )}
            </CompanyContactDetail>
          </CompanyContactDetailBlock>
        )}
        {hasContactInfo && (
          <CompanyContactDetailBlock isEmptyProfile={isEmptyProfile}>
            <CompanyContactIcon
              iconName={'icon-label-location-pin'}
              iconSize={'24px'}
            />
            <CompanyContactDetail>
              <CompanyOrgName>{profile.address?.name}</CompanyOrgName>
              {profile.address?.street && <div>{profile.address.street}</div>}
              <div>
                {profile.address?.zipCode && (
                  <span> {profile.address.zipCode}&nbsp;</span>
                )}
                {profile.address?.city && <span>{profile.address.city}</span>}
              </div>
              {profile.address?.countryCode && (
                <div>
                  {formatMessage({
                    id: 'CORE.COUNTRIES.' + profile.address.countryCode
                  })}
                </div>
              )}
            </CompanyContactDetail>
          </CompanyContactDetailBlock>
        )}
        {__contactExtensions && (
          <CompanyContactDetailBlock
            dangerouslySetInnerHTML={{ __html: __contactExtensions }}
          />
        )}
      </ContactInfoSection>
      {profile.organization?.hasMoreProfiles &&
        (isOpenProfile || (isMemberProfile && profileHasReviews)) && (
          <MultiProfileWrapper isEmptyProfile={isEmptyProfile}>
            <div>
              {formatMessage({
                id: 'COMPANYPROFILES.LEAVE_A_REVIEW_BOX.NO_REVIEWS_IN_PROFILE.BODY'
              })}
            </div>
            <MultiCompanyLinkAsButton
              isEmptyProfile={isEmptyProfile}
              context={Context.Secondary}
              href={multiStoreUrl}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <GoToCompanyCTAText>
                {formatMessage({
                  id: 'COMPANYPROFILES.RATINGS.LIST.NO_RATINGS.CTA'
                })}
              </GoToCompanyCTAText>
              <ExternalLinkIcon
                iconName="icon-action-open-in-new-tab"
                iconSize="22px"
              />
            </MultiCompanyLinkAsButton>
          </MultiProfileWrapper>
        )}
      {((profile.legalInformation?.jurisdiction &&
        profile.legalInformation.commercialRegisterNumber) ||
        profile.legalInformation?.responsibleEntity) && (
        <LegalContactsSection
          alignment={'left'}
          hasNoReviews={!isSummaryVisible}
        >
          {profile.legalInformation?.jurisdiction &&
            profile.legalInformation.commercialRegisterNumber && (
              <LegalContact>
                <LegalHeadline as={'h4'}>
                  {formatMessage({
                    id: 'COMPANYPROFILES.CONTACT_INFO.COMMERCIAL_REGISTER'
                  })}
                </LegalHeadline>
                <LegalInfoText>
                  {profile.legalInformation?.jurisdiction +
                    ' ' +
                    profile.legalInformation?.commercialRegisterNumber}
                </LegalInfoText>
              </LegalContact>
            )}
          {profile.legalInformation?.responsibleEntity && (
            <>
              <LegalContact>
                <LegalHeadline as={'h4'}>
                  {formatMessage({
                    id: 'COMPANYPROFILES.CONTACT_INFO.AUTHORIZED_REPRESENTATIVE'
                  })}
                </LegalHeadline>
                <LegalInfoText>
                  {profile.legalInformation?.responsibleEntity}
                </LegalInfoText>
              </LegalContact>
              <LegalContact />
            </>
          )}
        </LegalContactsSection>
      )}
      {profile.categories && profile.categories.length > 0 && (
        <>
          <SectionSeparator />
          <CompanyDetailsSectionHeadline as={'h4'}>
            {formatMessage({ id: 'COMPANYPROFILES.DETAILS.CATEGORIES.LABEL' })}
          </CompanyDetailsSectionHeadline>
          <Categories allCategories={allCategoriesAndKeywords} />
        </>
      )}
    </CompanyContactInfo>
  );
};

export default ContactInfo;
